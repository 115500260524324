<template>
  <div class="tab-free-list">
    <ul class="tab-free-ul">
      <li v-for="item in freeData" :key="item.id" @click="goHref(item.link)">
        <img :src="item.cover_img" />
        <h1>{{ item.title }}</h1>
        <div class="data">
          <span>{{ item.open }}次观看</span>
          <b>免费</b>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getClassList } from "../js/index.js";
export default {
  data() {
    return {
      freeData: [],
    };
  },

  async created() {
    let data = await getClassList({
      type: "免费",
      pageSize: 100,
    });
    this.freeData = data.list;
  },
  methods: {
    goRouter(name) {
      this.$router.push({
        name,
      });
    },
    goHref(src) {
      window.location.href = src;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.tab-free-list {
  padding: 0 rem(30);
  margin: 0 auto;
  width: rem(690);
}
.tab-free-ul {
  display: flex;
  flex-wrap: wrap;
  margin: rem(32) 0 rem(10) 0;
  li {
    background: #ffffff;
    width: rem(337);
    margin-bottom: rem(24);
    // border-radius: rem(16) rem(16) 0 0;
    border-radius: rem(16);
    &:nth-of-type(2n-1) {
      margin-right: rem(16);
    }
    img {
      width: 100%;
      height: rem(253);
      display: block;
      border-radius: rem(16) rem(16) 0 0;
    }
    h1 {
      font-weight: bold;
      font-size: rem(26);
      color: #222222;
      margin: rem(16) 0px rem(10);
      padding: 0 rem(16);
      box-sizing: border-box;
    }
    .data {
      display: flex;
      color: #989898;
      font-size: rem(22);
      margin-bottom: rem(24);
      padding: 0 rem(16);
      box-sizing: border-box;

      b {
        color: #ff4848;
      }
      span {
        flex: 1;
      }
    }
  }
}
</style>
